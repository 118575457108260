<!--
居家监护
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeIndex = 1"
        >
          设备信息
        </div>
      </div>
    </div>

    <!--维护记录-->
    <div class="shipMain">
      <!--设备信息-->
      <div class="shipNav">
        <img
          src="@/assets/img/homeGuardianship/pressTitle.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">设备信息</span>
      </div>

      <div class="mentList">
        <div
          class="mentItem"
          v-for="(item, index) in userDeviceList"
          :key="index"
        >
          <!-- v-if="item.isSetup == 1" -->
          <img
            src="@/assets/img/homeGuardianship/online.png"
            alt=""
            class="imgTip"
          />
          <!-- <img
            src="@/assets/img/homeGuardianship/offline.png"
            alt=""
            class="imgTip"
            v-else
          /> -->

          <img :src="item.equipmentUrl" alt="" class="mentIcon" />
          <div class="itemInfo">
            <p class="bigInfo">{{ item.equipmentName }}</p>
            <p>{{ item.equipmentModel }}</p>
            <p>ID {{ item.equipmentNo }}</p>
          </div>
        </div>
      </div>

      <div class="shipNav navMargin">
        <img
          src="@/assets/img/homeGuardianship/pressTitle.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">维护记录</span>
      </div>

      <!-- table -->
      <div class="tableBox">
        <el-table
          :data="tableData"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="date" label="档案编号"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="address" label="身份证号"> </el-table-column>
          <el-table-column prop="address" label="手机号码"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { GetUserDevice } from "../../api/homeGuardianship";
export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
      tableData: [],
      userDeviceList: [],
    };
  },
  created() {
    this.getUserDevice();
  },

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "double-row";
      }
      return "";
    },
    getUserDevice() {
      GetUserDevice({ bizType: "2" }).then((res) => {
        let data = res.result;
        this.userDeviceList = data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.statusBox {
  min-width: 75px;
  min-height: 26px;
  border-radius: 13px 13px 13px 13px;
  line-height: 26px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  display: inline-block;
}
.mentList {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.mentItem {
  display: flex;
  padding: 20px 10px 0px 25px;
  width: 188px;
  min-height: 95px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  margin-right: 30px;
  margin-bottom: 20px;
  position: relative;
}
.mentIcon {
  width: 48px;
  height: 48px;
  margin-right: 15px;
}
.itemInfo p {
  font-size: 12px;
  font-weight: 400;
  color: #476bfe;
  margin-bottom: 5px;
  word-break: break-all;
}
.itemInfo {
  flex: 1;
  overflow: hidden;
}
.bigInfo {
  font-size: 16px !important;
  font-weight: 400;
  color: #476bfe;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.imgTip {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
}
</style>
